<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import Gen from '@helper/Gen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoHome",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoHome",
        home_hero: {},
        overview_kesehatan: {},
        overview_recipe: {},
        overview_shop: {},
        row2: {},
        slTitleLength: 0,
        ashTitleLength: 0,
        ashSubTitleLength: 0,
        ashDescLength: 0,
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData(() => {
        setTimeout(() => {
          this.$set(this.row, 'type', 'update')
        }, 500)
      })
    },
    methods: {
      submitHero() {
        BOGen.apirest('/' + this.Name, {
          data: this.home_hero,
          type: 'updateHero'
        }, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hero-info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.hero-info')
            this.refreshData()
            setTimeout(() => {
              $('#collapseExample').hide()
            }, 3500)
          }
        }, "POST");
      },
      submitOverview(e) {
        if(e&&e.btnLoading()) return;
				BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
          if(e) e.btnUnloading()
					if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.col-info')
					if (resp.success) {
						Gen.info(resp.message, 'success', 2000, '.col-info')
            this.refreshData()
            setTimeout(() => {
              $('#collapseExample2').hide()
            }, 2000)
          }
        }, "POST");
      },
      addSlide() {
        this.row = {
          ahh_is_active: "Y"
        }
        this.row.type = 'add'
      },
      editSlide(v) {
        $('#collapseExample').css("display", "flex")
        // if ($("#collapseExample").css("display") == "none") $("#collapseExample").css("display","flex")
        // else $("#collapseExample").hide()
        this.row = v;
        this.row.type = 'update'
      },
      editOverview(v) {
        $('#collapseExample2').css("display", "flex")
        console.log("edit overview")
        // if ($("#collapseExample2").css("display") == "none") $("#collapseExample2").css("display","flex")
        // else $("#collapseExample2").hide()
        this.row2 = v
        this.row2.type = 'updateOverview'
      },
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.home_hero,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
    },
    watch: {
      '$route.query'(v) {
        this.refreshData()
      },
      'row.ahh_title'(v){
        this.slTitleLength = v.length
      },
      'row2.ash_title'(v){
        this.ashTitleLength = v.length
      },
      'row2.ash_sub_title'(v){
        this.ashSubTitleLength = v.length
      },
      'row2.ash_desc'(v){
        this.ashDescLength = v.length
      },   
    }
  }
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Slide Show</h5>
        </div>

        <div class="card-body">
          <div class="alert alert-info">
            Image Requirements for Slide Show :
            <ul class="mb-0 mt-2">
              <li><strong>Dimension: </strong><span>1350x550px (Desktop), 768x1285px (Mobile)</span></li>
              <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
              <li><strong>Max. Size: </strong><span>2 MB</span></li>
            </ul>
          </div>
          <draggable v-model="home_hero" class="row" tag="tbody" @end="endDrag">
            <div style="cursor:move;" class="col-md-4 mb-3" :id="'dat'+v.ahh_id" v-for="(v,k) in home_hero" :key="k">
              <div class="wrap_slider_img">
                <img :src="uploader(v.ahh_img)" class="img-responsive" />
                <div class="slider_name">
                  <p>{{v.ahh_title}}</p>
                </div>
                <a data-toggle="collapse" @click="editSlide(v)" href="javascript:;" class="bullet_edit"><i
                    class="ti-marker-alt"></i></a>
                <a href="javascript:;" @click="deleteItemId(v.ahh_id,'dat'+v.ahh_id)" class="bullet_delete"><i
                    class="far fa-trash-alt"></i></a>
                <span class="label label-success" v-if="v.ahh_is_active == 'Y'">Active</span>
                <span class="label label-danger" v-else>Inactive</span>
              </div>
            </div>

            <div class="col-md-4" :class="home_hero.length > 3 ? 'mt-3':''">
              <a class="wrap_upload" data-toggle="collapse" @click="addSlide" href="#collapseExample" role="button"
                aria-expanded="false" aria-controls="collapseExample">
                <div class="ic_wrap">
                  <i class="fas fa-plus"></i>
                  <p>New Slider</p>
                </div>
              </a>
            </div>
          </draggable>
          <VForm @resp="submitFormSlider" method="post">
            <div class="row collapse mt-4" id="collapseExample">
              <div class="col-12 info"></div>
              <div class="col-md-8 mb-3">
                <BoField name="ahh_img" mandatory>
                  <Uploader :param="{thumbnail:true}" name="ahh_img" v-model="row.ahh_img" type="hero_desktop"
                    uploadType="cropping"></Uploader>
                </BoField>
                <BoField name="ahh_title" v-model="row.ahh_title"></BoField>
                <small class="mb-2 d-inline-block text-info">{{validation('ahh_title').maxlength-slTitleLength}} character remaining </small>
                <BoField name="ahh_link" v-model="row.ahh_link"></BoField>
                <BoField name="ahh_is_active">
                  <div class="row">
                    <div class="col-md-2">
                      <radio name="ahh_is_active" v-model="row.ahh_is_active" option="Y"
                        :attr="validation('ahh_is_active')">Active</radio>
                    </div>
                    <div class="col-md-2">
                      <radio name="ahh_is_active" v-model="row.ahh_is_active" option="N">Inactive</radio>
                    </div>
                  </div>
                </BoField>
              </div>
              <div class="col-md-4 mb-3">
                <BoField name="ahh_img_mobile" mandatory>
                  <Uploader :param="{thumbnail:true}" name="ahh_img_mobile" v-model="row.ahh_img_mobile"
                    type="hero_mobile" uploadType="cropping"></Uploader>
                </BoField>
              </div>
              <div class="col-4 mt-3">
                <button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="card-header">

          <h5 class="card-title">Overview</h5>
        </div>


        <div class="card-body">
          <div class="alert alert-info">
            Image Requirements for Overview :
            <ul class="mb-0 mt-2">
              <li><strong>Dimension: </strong><span>300x300px</span></li>
              <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
              <li><strong>Max. Size: </strong><span>2 MB</span></li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="wrap_slider_img wrap_banner_main">
                <img :src="uploader(overview_kesehatan.ash_img)" class="img-responsive" />
                <div class="slider_name">
                  <p>{{overview_kesehatan.ash_title}}</p>
                </div>
                <a @click="editOverview(overview_kesehatan)" class="bullet_edit"><i class="ti-marker-alt"></i></a>
                <!-- <span class="label label-success" v-if="overview_kesehatan.asc_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="wrap_slider_img wrap_banner_main">
                <img :src="uploader(overview_recipe.ash_img)" class="img-responsive" />
                <div class="slider_name">
                  <p>{{overview_recipe.ash_title}}</p>
                </div>
                <a @click="editOverview(overview_recipe)" class="bullet_edit"><i class="ti-marker-alt"></i></a>
                <!-- <span class="label label-success" v-if="overview_recipe.asc_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="wrap_slider_img wrap_banner_main">
                <img :src="uploader(overview_shop.ash_img)" class="img-responsive" />
                <div class="slider_name">
                  <p>{{overview_shop.ash_title}}</p>
                </div>
                <a @click="editOverview(overview_shop)" class="bullet_edit"><i class="ti-marker-alt"></i></a>
                <!-- <span class="label label-success" v-if="overview_shop.asc_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span> -->
								</div>
							</div>
						</div>
						<div class="row collapse mt-4" id="collapseExample2">
							<div class="col-md-4 mb-3">
								<BoField name="ash_img">
									<Uploader name="ash_img" :param="{thumbnail:true}" type="overview"
										uploadType="cropping" v-model="row2.ash_img"></Uploader>
								</BoField>
							</div>
							<div class="col-md-8 mb-3">
								<VForm @resp="submitOverview" method="post">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Overview Information</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-12 col-info"></div>
														<div class="col-md-6">
															<BoField name="ash_title" :attr="{maxlength:'100',minlength:'3',required:'required'}" v-model="row2.ash_title" mandatory>
															</BoField>
                              <small class="mb-2 d-inline-block text-info">{{100-ashTitleLength }} character remaining </small>
														</div>
														<div class="col-md-6">
															<BoField name="ash_sub_title" v-model="row2.ash_sub_title" mandatory :attr="{maxlength:'250',minlength:'3'}">
															</BoField>
                              <small class="mb-2 d-inline-block text-info">{{250-ashSubTitleLength}} character remaining </small>
														</div>
														<div class="col-md-12">
															<BoField name="ash_desc" mandatory >
                                <textarea v-model="row2.ash_desc" required="" maxlength='350' minlength='3' row="4" class="form-control"></textarea>
															</BoField>
                              <small class="mb-2 d-inline-block text-info">{{350-ashDescLength}} character remaining </small>
														</div>
														<div class="col-sm-4">
															<BoField name="ash_is_btn">
																<div class="row">
																	<div class="col-4">
																		<radio name="ash_is_btn"
																			v-model="row2.ash_is_btn" option="Y"
																			:attr="validation('ash_is_btn')">Active
																		</radio>
																	</div>
																	<div class="col-4">
																		<radio name="ash_is_btn"
																			v-model="row2.ash_is_btn" option="N">
																			Inactive
																		</radio>
																	</div>
																</div>
															</BoField>
														</div>
                            <div class="col-md-8"></div>
														<div class="col-sm-4" v-if="row2.ash_is_btn=='Y'">
															<BoField name="ash_text_btn" v-model="row2.ash_text_btn"></BoField>
														</div>
														<div class="col-sm-8" v-if="row2.ash_is_btn=='Y'">
															<BoField name="ash_link" v-model="row2.ash_link"></BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
                    <div class="col-4 mt-3">
                      <a href="#" class="btn btn-rounded btn-block btn-success" data-toggle="modal" data-target="#onbPreview">Preview <i class="icon-eye ml-2"></i></a>
                    </div>
										<div class="col-4 mt-3">
											<button type="submit"
												class="btn btn-rounded btn-block btn-loading btn-info">Submit</button>
										</div>
									</div>
								</VForm>
							</div>
						</div>
					</div>
				</div>
			</div>
      <div id="onbPreview" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Preview</h5>
              <button type="button" class="close" data-dismiss="modal">×</button>
            </div>

            <div class="modal-body">
              <div :class="'onb-preview prev'+row2.ash_id">
                <VImg :src="uploader(row2.ash_img)"></VImg>
                <div class="bg-text">
                  <div class="bgt-title">
                    <h3>{{row2.ash_title}}</h3>
                    <strong>{{row2.ash_sub_title}}</strong>
                  </div>
                  <div class="bgt-caption">
                    <p>{{row2.ash_desc}}</p>
                    <a :href="row2.ash_link" target="_blank" class="box-btn" v-if="row2.ash_is_btn=='Y'">{{row2.ash_text_btn}}</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>